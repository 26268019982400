<template>
  <div id="app">
    <component :is="header">
    </component>
    <b-container fluid="md" id="container">
      <router-view/>
    </b-container>
    <Footer/>
  </div>
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./pages/public/assets/css/main.scss"
import Footer from "./pages/public/components/Footer"

export default {
  computed: {
    header() {
      let isAdminRoute = this.$route.fullPath.startsWith("/admin")
      if (isAdminRoute) {
        return 'admin-header'
      } else if (this.$route.fullPath.startsWith("/login")) {
        return ''
      }
      return 'public-header'
    }
  },
  components: {
    Footer
  }
}
</script>