<template>
  <div>
    <b-alert v-model="errorOccurred" variant="danger" dismissible>
      {{ $t('contact-us.sendError') }}
    </b-alert>

    <p v-if="state.confirmation">{{ $t('contact-us.sendConfirmation') }}</p>

    <b-form @submit="onSubmit" @reset="onReset" v-if="!state.confirmation">
      <b-card>
        <b-form-group label-cols-lg="3"
                      :label="$t('contact-us.personal-informations.group-name')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">

          <b-form-group label-cols-sm="3"
                        :label="$t('contact-us.personal-informations.name')"
                        label-align-sm="right"
                        label-for="contactus-employer-form-name">
            <b-form-input id="contactus-employer-form-name"
                          v-model="form.name"/>
          </b-form-group>

          <b-form-group label-cols-sm="3"
                        :label="$t('contact-us.personal-informations.e-mail')"
                        label-align-sm="right"
                        label-for="contactus-employer-form-email">
            <b-form-input id="contactus-employer-form-email"
                          v-model="form.email"
                          type="email"/>
          </b-form-group>
          <b-form-group label-cols-sm="3"
                        :label="$t('contact-us.personal-informations.message')"
                        label-align-sm="right"
                        type="text"
                        label-for="contactus-employer-form-message">
            <b-form-textarea id="contactus-employer-form-message"
                             v-model="form.message"
                             rows="5"
                             max-rows="15"/>
          </b-form-group>
        </b-form-group>
      </b-card>

      <b-card>
        <b-button type="submit"
                  variant="primary"
                  class="float-right">
          {{ $t('contact-us.button.submit') }}
        </b-button>
        <b-button type="reset"
                  variant="danger"
                  class="float-right">
          {{ $t('contact-us.button.reset') }}
        </b-button>
      </b-card>

    </b-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactUs",
  data() {
    return {
      errorOccurred: false,
      state: {
        confirmation: false
      },
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    onSubmit(evt) {
      this.$log.debug("submit of the contact use employer form", this.form)
      evt.preventDefault();
      axios.post("/api/message", this.form)
          .then(() => {
            this.state.confirmation = true
          })
          .catch(error => {
            this.$log.error(error);
            this.state.confirmation = false
            this.errorOccurred = true
          })
    },
    onReset(evt) {
      this.$log.debug("reset of the contact use employer form")
      evt.preventDefault();
      this.state.confirmation = false
      this.errorOccurred = false
      this.form = {
        name: '',
        email: '',
        message: ''
      }
    }
  }
}
</script>