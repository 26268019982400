<template>
  <div id="employer">
    <section>
      <h1>{{ $t('employer.title') }}</h1>
      <h3 class="mt-3">{{ $t('employer.synopsis') }}</h3>
      <p>
        <!-- add break line and mx flag-->
        <i18n path="employer.introduction">
          <br/>
          <flag iso="MX"/>
        </i18n>
      </p>
    </section>

    <section>
      <h3>{{ $t('employer.services.title') }}</h3>

      <b-row cols-md="2" cols-lg="2" cols-xl="2" cols="1" class="mt-5">
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fab', 'searchengin']" size="5x" class="float-left mr-2"/>
          {{ $t('employer.services.definition-of-needs') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'puzzle-piece']" size="5x" class="float-left mr-2"/>
          {{ $t('employer.services.candidates-search') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'folder-open']" size="5x" class="float-left mr-2"/>
          {{ $t('employer.services.candidate-proposition') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'hands-helping']" size="5x" class="float-left mr-2"/>
          {{ $t('employer.services.support-visas') }}
        </b-col>
      </b-row>
    </section>

    <section>
      <h3>{{ $t('employer.request.title') }}</h3>
      <i18n path="employer.request.contact-us">
        <router-link to="/contact-us">
          {{ $t('menu.contact-us') }}
        </router-link>
      </i18n>
    </section>

  </div>
</template>

<script>
export default {
  name: "Employer"
}
</script>