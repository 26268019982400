<template>
  <div id="worker">
    <section>
      <h1>{{ $t('worker.title') }}</h1>
      <span>{{ $t('worker.synopsis') }}</span>

      <b-row cols-md="2" cols-lg="2" cols-xl="2" cols="1" class="mt-5">
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fab', 'searchengin']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.analyses') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'puzzle-piece']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.matching') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['far', 'check-circle']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.validation') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'folder-open']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.proposal') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'hands-helping']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.accompaniment') }}
        </b-col>
        <b-col class="pb-3">
          <font-awesome-icon :icon="['fas', 'map-signs']" size="5x" class="float-left mr-2"/>
          {{ $t('worker.values.assist') }}
        </b-col>
      </b-row>
    </section>

    <section>
      <h3 class="mt-4 mb-4">{{ $t('worker.needs.title') }}</h3>

      <b-row cols-md="2" cols-lg="3" cols-xl="3" cols="1">
        <b-col>
          <b-card v-bind:header="$t('worker.needs.agriculture.title')" class="mb-4"
                  v-bind:footer="$t('worker.needs.agriculture.date')">
            <b-card-img v-bind:alt="$t('worker.needs.agriculture.title')"
                        :src="require('../assets/img/workers/apple-tree.jpg')"/>
          </b-card>
        </b-col>
        <b-col>
          <b-card v-bind:header="$t('worker.needs.construction.title')" class="mb-4"
                  v-bind:footer="$t('worker.needs.construction.date')">
            <b-card-img v-bind:alt="$t('worker.needs.construction.title')"
                        :src="require('../assets/img/workers/tools-864983_640.jpg')"/>
          </b-card>
        </b-col>
        <b-col>
          <b-card v-bind:header="$t('worker.needs.informatique.title')" class="mb-4"
                  v-bind:footer="$t('worker.needs.informatique.date')">
            <b-card-img v-bind:alt="$t('worker.needs.informatique.title')"
                        :src="require('../assets/img/workers/industry-4-2741774_640.png')"/>
          </b-card>
        </b-col>
        <b-col>
          <b-card v-bind:header="$t('worker.needs.health.title')" class="mb-4"
                  v-bind:footer="$t('worker.needs.health.date')">
            <b-card-img v-bind:alt="$t('worker.needs.health.title')"
                        :src="require('../assets/img/workers/caduceus-30591_640.png')"/>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
export default {
  name: "Worker"
}
</script>