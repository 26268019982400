import Vue from 'vue'
import './plugins/logger'
import '@/plugins/fontawesome'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store/index'
import {BootstrapVue} from 'bootstrap-vue'
import i18n from './plugins/i18n'
import FlagIcon from 'vue-flag-icon'
import PublicHeader from './pages/public/components/PublicHeader'
import AdminHeader from './pages/admin/components/AdminHeader'

Vue.config.productionTip = true
Vue.use(BootstrapVue)
Vue.use(FlagIcon)

Vue.component('public-header', PublicHeader)
Vue.component('admin-header', AdminHeader)

new Vue({
    router,
    store,
    BootstrapVue,
    FlagIcon,
    i18n,
    axios,
    render: h => h(App)
}).$mount('#app');
