<template>
  <b-navbar-nav id="nav-languages">
    <b-nav-item-dropdown id="nav-languages-dropdown" right :toggle-class="getIcon($i18n.locale)">
      <b-dropdown-item-button v-for="locale in locales"
                              :key="locale.name"
                              :value="locale.code"
                              @click="switchLangage(locale.code)">
        <flag :iso="locale.code"/>
        {{ locale.name }}
        <!--todo tooltips-->
      </b-dropdown-item-button>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import VueI18n from "./../../plugins/i18n"

export default {
  name: "LocaleSwitcher",

  data() {
    return {
      switchLangage: function (lang) {
        this.$log.debug("change language", lang)
        VueI18n.locale = lang
      },
      getIcon:function (lang){
        return "language-switcher-flag flag-icon flag-icon-squared flag-icon-" + lang
      },
      locales: [
        {code: "fr", name: "Français"},
        {code: "gb", name: "English"},
        {code: "es", name: "Español"}
      ]
    }
  }
}
</script>