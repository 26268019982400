import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Worker from '../pages/public/views/Worker'
import Employer from '../pages/public/views/Employer'
import ContactUs from '../pages/public/views/ContactUs'
import Message from '../pages/admin/views/Message'
import MessageDetails from '../pages/admin/views/MessageDetails'
import Login from '../pages/commons/Login'

Vue.use(VueRouter)

const routes = [
    //TODO add locale in URL
    {path: '/', redirect: {name: 'Worker'}},
    {path: '/worker', name: 'Worker', component: Worker},
    {path: '/employer', name: 'Employer', component: Employer},
    {path: '/contact-us', name: 'ContactUs', component: ContactUs},
    {path: '/login', name: 'Login', component: Login},
    {path: '/admin', name: 'Message', component: Message, meta: {secured: true}},
    {path: '/admin/message', name: 'Message', component: Message, meta: {secured: true}},
    {path: '/admin/message/:id', component: MessageDetails, meta: {secured: true}}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(((to, from, next) => {
    if (to.matched.some(record => record.meta.secured)) {
        Vue.$log.debug("route secured", to.fullPath)
        store.getters.isLoggedIn.then(isLogged => {
            if (isLogged) {
                Vue.$log.debug("user already connected")
                next()
            } else {
                Vue.$log.debug("user not connected, redirect to login page")
                next({
                    name: 'Login',
                    query: {from: to.fullPath}
                })
            }
        })
    } else {
        Vue.$log.debug("the route doesn't need login", to.fullPath)
        next()
    }
}))

export default router
