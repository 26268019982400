import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        user: ''
    },
    mutations: {
        auth_success(state, token, user) {
            state.token = token
            state.user = user
        },
        logout(state) {
            state.token = ''
            state.user = ''
        },
    },
    actions: {
        login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                axios({url: '/authenticate', data: credentials, method: 'POST'})
                    .then(response => {
                        let token = response.data.token
                        let user = response.data.user

                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = "Bearer " + token
                        commit('auth_success', token, user)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error("not possible to authenticate the user", credentials.username)
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => {
            let token = state.token || localStorage.getItem('token')
            if (token) {
                return axios({url: '/token/validate', data: state.token, method: 'POST'})
                    .then(response => {
                        return response.status === 202
                    }).catch(error => {
                        console.error("the token is not valid", error)
                        return false
                    })
            } else {
                return new Promise((resolve) => {
                    resolve()
                })
            }
        }
    }
})
