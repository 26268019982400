<template>
  <b-navbar id="header" tag="header" toggleable="lg" type="dark" variant="secondary" fixed="top" sticky>
    <b-navbar-brand to="/">
      <b-img class="logo" :src="require('/public/logo.svg')"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <!-- Left aligned nav items -->
      <b-navbar-nav id="nav-links">
        <b-nav-item to="/worker">{{ $t('menu.worker') }}</b-nav-item>
        <b-nav-item to="/employer">{{ $t('menu.employer') }}</b-nav-item>
        <b-nav-item to="/contact-us">{{ $t('menu.contact-us') }}</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <LocaleSwitcher/>
        </b-nav-item>
        <b-nav-item to="/admin" style="align-self: center">
          <font-awesome-icon :icon="['fas', 'sign-in-alt']" size="lg" color="black"/>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import LocaleSwitcher from "../../commons/LocaleSwitcher"

export default {
  name: 'Header',
  components: {
    LocaleSwitcher
  }
}
</script>