<template>
<div>
  message
  <b-link to="/admin/message/1"> oink</b-link>
</div>
</template>

<script>
export default {
  name: "Message"
}
</script>

<style scoped>

</style>