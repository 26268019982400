<template>
  <b-navbar id="header" tag="header" toggleable="lg" type="dark" variant="secondary" fixed="top" sticky>
    <b-navbar-brand>
      <router-link to="/admin">
        <b-img class="logo" :src="require('/public/logo.svg')"/>
      </router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Left aligned nav items -->
      <b-navbar-nav id="nav-links" href="/admin">
        <b-nav-item href="/">public page</b-nav-item>
        <b-nav-item to="/admin/message">message</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <b-button @click="logout">
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="lg" color="black"/>
          </b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>

export default {
  name: 'AdminHeader',
  methods: {
    logout() {
      this.$log.debug("logout asked")
      this.$store.dispatch('logout').then(() => {
        this.$log.debug("logout ok redirect to home page")
        this.$router.push("/")
      }).catch(error => {
        this.$log.error("error during the process of logout", error)
      })
    }
  }
}
</script>