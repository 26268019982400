<template>
  <div>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-form @submit="login">
          <router-link to="/">
            <b-img class="logo mb-4" :src="require('/public/logo.svg')" width="72" height="57" center/>
          </router-link>
          <h1 class="h3 mb-3 fw-normal" style="text-align: center">Please sign in</h1>
          <b-form-group id="username-group" label="Username" label-for="username">
            <b-form-input id="username" v-model="username" autocomplete="username"/>
          </b-form-group>

          <b-form-group id="password-group" label="Password" label-for="password">
            <b-form-input type="password" id="password" v-model="password" autocomplete="password"/>
          </b-form-group>
          <b-alert v-model="error" variant="danger" dismissible>
            {{ this.errorMessage }}
          </b-alert>
          <b-button block class="btn-lg" type="submit">Sign in</b-button>
        </b-form>

      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    login(evt) {
      evt.preventDefault();
      let username = this.username
      let password = this.password
      this.$store.dispatch('login', {username, password})
          .then(() => {
            if (this.$route.query.from) {
              this.$router.push(this.$route.query.from)
            } else {
              this.$router.push('/')
            }
          })
          .catch((error) => {
            this.$log.error("error when trying to authenticate", error)
            this.error = true
            this.errorMessage = 'username or password not valid'
          })
    }
  }
}
</script>