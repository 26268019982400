<template>
<div>
  message details
  <h2>message id {{ $route.params.id }}</h2>
</div>
</template>

<script>
export default {
  name: "MessageDetails"
}
</script>

<style scoped>

</style>